<template>
    <div>
        <v-row v-if="membershipdata != ''"
               style="padding-left: 30px;padding-right: 30px;"
               class="pb-7">
            <!-- {{ membershipdata }} -->
            <iframe :src="membershipdata"></iframe>
        </v-row>
    </div>
</template>

<script>
import store from '@/store';
import { mdiClose } from '@mdi/js';

import authServices from '@/services/auth-services';

import moment from 'moment';

export default {

    components: {},

    setup()
    {
        return {
            icons: {
                mdiClose
            },
        }
    },
    data: () => ({
        selectedLanguage: 'en',
        membershipdata: '',
        selectedCard: null,
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        });

        this.postMembership();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        };

        this.postMembership();
    },
    methods: {
        postMembership()
        {
            this.selectedCard = store.get('card/details');

            let newDate = moment(new Date()).format('DDMMyyyyHHmmss');

            let url = "https://online.americanexpress.com.sa/rewards/SSOApp/ssologin.aspx?timestamp=" + newDate + "&access=customer.redemption";

            let token = 'Bearer ' + store.get('requestKeys/accessToken');


            if (this.selectedCard.mRAccountNo != "" && this.selectedCard.mRAccountStatus == "N")
            {
                url = "https://online.americanexpress.com.sa/rewards/SSOApp/ssologin.aspx?timestamp=" + newDate + "&access=customer.redemption";
                token = 'Bearer ' + store.get('requestKeys/accessToken');
            }
            else
            {
                url = 'https://online.americanexpress.com.sa/rewards/index.aspx';
                token = '';
            }


            let data = {
                timestamp: newDate,
                // token: 'Bearer ' + store.get('requestKeys/accessToken')
                token: token
            }



            var form, input;

            form = document.createElement("form");
            form.method = "post";
            form.enctype = 'application/x-www-form-urlencoded';
            form.action = url;
            input = document.createElement("input");
            input.setAttribute("name", "token");
            input.setAttribute("value", data.token);
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-app-bar-title__conten {
    bottom: 115px !important;
}

.offer-detail-width {
    width: 60%;
    // height: 400px;
    // overflow: hidden;
    margin: auto;
}

.offers-popup-data {
    height: 70vh !important;
}

@media screen and (max-width: 809px) {
    .offer-detail-width {
        width: 98%;
        // height: 95vh;
        // overflow: hidden;
    }
}

.terms {
    li {
        font-size: 10px;
    }
}

.dilog-cross {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;
}

.icon-detail {
    position: relative;
    top: -123px;
}

.icon-image {
    background: #F3F4F9;
    padding: 12px 7px 4px;
    border-radius: 10px;
    margin-left: 33px;
    text-shadow: 1px 1px black;
}

@media screen and (max-width: 600px) {
    // .offers-popup-data {
    //     height: auto !important;
    // }
}
</style>
